import { mapGetters } from 'vuex'
import PricePerMonth from '@/components/core/PricePerMonth'
import CXProductCard from '@/components/cx/pricing/CXProductCard'
import CXProductFeatureList from '@/components/cx/pricing/CXProductFeatureList'
import CXProductCardWrapper from '@/components/cx/pricing/CXProductCardWrapper'

export default {
  data () {
    return {
      planName: ''
    }
  },

  components: {
    PricePerMonth,
    CXProductCard,
    CXProductFeatureList,
    CXProductCardWrapper
  },

  computed: {
    ...mapGetters('cx/payment', ['getPlanCommitmentFor']),
    ...mapGetters(['subscription']),

    isCurrentPlan () {
      return this.planName === this.subscription.planSlug
    },

    commitment () {
      return this.planName ? this.getPlanCommitmentFor(this.planName) : {}
    }
  }
}
