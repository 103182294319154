<template>
  <c-x-product-card-wrapper
    type="essential"
  >
    <c-x-product-card
      slot="top"
      :title="$t('pages.cx.pricing.plans.essential.title')"
      :current="isCurrentPlan"
      data-testid="PaymentPlan--Essential"
    >
      <heading
        size="3"
        color="black"
        class="is-uppercase"
      >
        {{ $t('pages.cx.pricing.free') }}
      </heading>
    </c-x-product-card>
    <c-x-product-feature-list
      slot="bottom"
      :features="features"
      type="essential"
      class="m-t-xl"
    />
  </c-x-product-card-wrapper>
</template>

<script>
import CXProductCardBaseMixin from '@/components/cx/pricing/CXProductCardBaseMixin'

/**
 * @module CXProductCardEssential
 */
export default {
  name: 'CXProductCardEssential',
  mixins: [CXProductCardBaseMixin],
  data () {
    return {
      features: ['1_stream', 'web_monitoring', 'newsroom', 'mobile_app'],
      planName: 'freemium'
    }
  }
}
</script>
