<template>
  <ul class="CXProductFeatureList">
    <li
      v-for="feature in features"
      :key="feature"
      class="is-position-relative m-b-s"
    >
      <v-icon type="check-o" class="has-text-primary is-position-absolute absolute-vertically-centered" />
      <div class="p-l-l">
        {{ $t(`pages.cx.pricing.plans.${type}.features.${feature}`) }}
      </div>
    </li>
  </ul>
</template>

<script>
/**
 * @module CXProductFeatureList
 */
export default {
  name: 'CXProductFeatureList',
  props: {
    type: {
      type: String,
      required: true
    },
    features: {
      type: Array,
      required: true
    }
  }
}
</script>
