<template functional>
  <div class="CXProductCardWrapper is-column is-full-height has-shadow border-grey-lightest border-2">
    <slot name="top" />
    <div class="p-a-m">
      <slot name="bottom">
        <heading
          color="black"
          size="6"
          gapless
          class="m-v-m"
        >
          {{ props.title }}
        </heading>
        <c-x-product-feature-list
          :features="props.features"
          :type="props.type"
          class="is-expanded"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import CXProductFeatureList from '@/components/cx/pricing/CXProductFeatureList'

/**
 * Just a wrapper component for the CXProductCards
 * Adds a shadow to the composed components
 * @module CXProductCardWrapper
 */
export default {
  name: 'CXProductCardWrapper',
  components: { CXProductFeatureList },
  props: {
    features: {
      type: Array,
      default: () => ([])
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  }
}
</script>
