<template>
  <v-button
    class="CXIntervalToggleButton is-unstyled has-text-primary"
    @click="toggleInterval"
  >
    {{ $t('general.click_here') }}
  </v-button>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

/**
 * @module CXIntervalToggleButton
 */
export default {
  name: 'CXIntervalToggleButton',
  computed: {
    ...mapState('cx/payment', ['interval'])
  },
  methods: {
    ...mapMutations('cx/payment', ['SET_INTERVAL']),

    toggleInterval () {
      this.SET_INTERVAL(this.interval === 'year' ? 'month' : 'year')
    }
  }
}
</script>
