<template>
  <c-x-product-card-wrapper
    :title="$t('pages.cx.pricing.plans.enterprise.catch')"
    :features="features"
    type="enterprise"
  >
    <c-x-product-card
      slot="top"
      :title="$t('pages.cx.pricing.plans.enterprise.title')"
      data-testid="PaymentPlan--Enterprise"
    >
      <heading
        size="3"
        color="black"
      >
        {{ $t('general.contact_us') }}
      </heading>
      <template slot="footer">
        <router-link
          :to="{ name: 'cx.contact_us' }"
          tag="v-button"
          class="is-primary"
        >
          {{ $t('general.contact') }}
        </router-link>
      </template>
    </c-x-product-card>
  </c-x-product-card-wrapper>
</template>

<script>
import CXProductCard from '@/components/cx/pricing/CXProductCard'
import CXProductCardBaseMixin from '@/components/cx/pricing/CXProductCardBaseMixin'

/**
 * @module CXProductCardEnterprise
 */
export default {
  name: 'CXProductCardEnterprise',
  components: { CXProductCard },
  mixins: [CXProductCardBaseMixin],
  data () {
    return {
      features: ['special_requirements', 'special_onboarding']
    }
  }
}
</script>
