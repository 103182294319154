<template>
  <div class="PricePerMonth columns is-mobile is-aligned-middle is-variable-grid is-1 has-text-weight-semibold">
    <div class="column has-text-right">
      <div class="is-size-3 leading-none">
        <!-- TODO: Someday add a dynamic symbol -->
        €{{ formatMoney(computedPrice, true, 'EUR', symbol, false) }}
      </div>
    </div>
    <div class="column is-size-7 has-text-left">
      <div>{{ $t('components.price_per_month.' + unit) }}</div>
      <div>{{ $t('components.price_per_month.per_month') }}</div>
    </div>
  </div>
</template>

<script>
import FormatMoneyMixin from '@hypefactors/shared/js/mixins/FormatMoneyMixin'

/**
 * @module PricePerMonth
 */
export default {
  name: 'PricePerMonth',
  mixins: [FormatMoneyMixin],
  props: {
    price: {
      type: [Number, String],
      required: true
    },
    symbol: {
      type: String,
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    interval: {
      type: String,
      required: true
    }
  },
  computed: {
    /**
     * Convert the price to a monthly installment
     * @return {number}
     */
    computedPrice () {
      return this.price / (this.interval === 'year' ? 12 : 1)
    }
  }
}
</script>
