<template>
  <div class="CXProductCard has-background-grey-lighter is-flex is-column is-fullwidth">
    <div
      :class="{ 'p-b-none': current }"
      class="is-expanded has-text-centered is-full-width is-flex is-column p-t-l p-a-m"
    >
      <heading color="black" size="4" gapless>
        {{ title.toUpperCase() }}
      </heading>
      <v-separator color="red" fat class="is-width-1/2 m-l-a m-r-a" />
      <div class="CXProductCard__content p-v-xl is-expanded has-text-black">
        <slot />
      </div>
      <div v-if="!current" class="CXProductCard__footer">
        <slot name="footer" />
      </div>
    </div>
    <div
      v-if="current"
      class="CXProductCard__CurrentCard has-text-centered p-b-m"
      data-testid="currentPlanTitle"
    >
      <div class="button is-primary is-fullwidth is-radiusless is-cursor-default">
        {{ $t('components.billing.current_plan') }}
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module CXProductCard
 */
export default {
  name: 'CXProductCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    current: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang='scss'>
@import '~utils';

@include tablet {
  .CXProductCard {
    min-height: 280px;
  }
}
</style>
