<template>
  <div class="CXPlansSection">
    <div
      :style="{ backgroundImage: `url(${$asset('/images/cx/pricing/cover.jpg')})` }"
      class="CXPlansSection__TopSection is-cover-image has-text-black"
    >
      <div :class="{ 'container container--padded': contained }">
        <slot name="TopSection">
          <div class="p-v-xxxl">
            <heading color="black" size="1">
              {{ $t('pages.cx.pricing.top_section_headline') }}
            </heading>
            <div class="has-text-weight-semibold is-size-5">
              {{ $t('pages.cx.pricing.top_section_content') }}
            </div>
          </div>
        </slot>
      </div>
    </div>
    <div class="CXPlansSection__BottomSection is-position-relative">
      <div
        :class="[{ 'container container--padded': contained }, bottomSectionClasses]"
      >
        <div class="columns is-multiline is-mobile is-variable-grid is-6">
          <div class="column is-12-mobile is-3-tablet">
            <c-x-product-card-essential />
          </div>
          <div class="column is-12-mobile is-3-tablet">
            <c-x-product-card-plus />
          </div>
          <div class="column is-12-mobile is-3-tablet">
            <c-x-product-card-pro />
          </div>
          <div class="column is-12-mobile is-3-tablet">
            <c-x-product-card-enterprise />
          </div>
        </div>
        <div class="CXPlansSection__smallPrint">
          <div class="m-b-m">
            {{ $t('pages.cx.pricing.top_section_small_print') }}
          </div>
          <div>
            <i18n :path="`pages.cx.pricing.top_section_small_print_${interval}`">
              <c-x-interval-toggle-button slot="link" />
            </i18n>
            <br>
            <router-link
              :to="{ name: 'cx.pricing', hash: '#comparison-table' }"
              class="has-text-primary"
            >
              {{ $t('pages.cx.pricing.see_full_comparison') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import CXProductCardPro from '@/components/cx/pricing/CXProductCardPro'
import CXProductCardPlus from '@/components/cx/pricing/CXProductCardPlus'
// import CXProductFeatureList from '@/components/cx/pricing/CXProductFeatureList'
import CXIntervalToggleButton from '@/components/cx/pricing/CXIntervalToggleButton'
import CXProductCardEssential from '@/components/cx/pricing/CXProductCardEssential'
import CXProductCardEnterprise from '@/components/cx/pricing/CXProductCardEnterprise'

/**
 * @module CXPlansSection
 */
export default {
  name: 'CXPlansSection',
  components: {
    CXIntervalToggleButton,
    // CXProductFeatureList,
    CXProductCardEnterprise,
    CXProductCardPro,
    CXProductCardPlus,
    CXProductCardEssential
  },
  props: {
    contained: {
      type: Boolean,
      default: true
    },
    bottomSectionClasses: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('cx/payment', ['interval'])
  }
}
</script>
<style>
.CXPlansSection__TopSection {
  padding-bottom: 10rem;
}

.CXPlansSection__BottomSection {
  margin-top: -12rem;
}
</style>
