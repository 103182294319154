<template>
  <c-x-product-card-wrapper
    :title="$t('pages.cx.pricing.plans.professional.catch')"
    :features="features"
    type="professional"
  >
    <c-x-product-card
      slot="top"
      :title="$t('pages.cx.pricing.plans.professional.title')"
      :current="isCurrentPlan"
      data-testid="PaymentPlan--Professional"
    >
      <price-per-month
        :price="commitment.amount"
        :symbol="commitment.currency"
        :interval="commitment.interval"
        unit="per_user"
      />
      <template slot="footer">
        <router-link
          :to="{ name: 'cx.manage-subscription', query: { plan: 'professional' } }"
          tag="v-button"
          class="is-primary"
          data-testid="CXProductCard__UpgradeButton"
        >
          {{ $t('general.upgrade') }}
        </router-link>
      </template>
    </c-x-product-card>
  </c-x-product-card-wrapper>
</template>

<script>
import CXProductCardBaseMixin from '@/components/cx/pricing/CXProductCardBaseMixin'

/**
 * @module CXProductCardPro
 */
export default {
  name: 'CXProductCardPro',
  mixins: [CXProductCardBaseMixin],
  data () {
    return {
      features: [
        'monitoring', 'print', 'reports', 'dashboard', 'measure', 'kpi', 'connect'
      ],
      planName: 'professional'
    }
  }
}
</script>
